const particlesConfig = {
  background: {
    color: {
        value: "#ffffff",
    },
},
fpsLimit: 120,
interactivity: {
    events: {
        onClick: {
            enable: true,
            mode: "push",
        },
        onHover: {
            enable: true,
            mode: "repulse",
        },
        resize: true,
    },
    modes: {
        push: {
            quantity: 3,
        },
        repulse: {
            distance: 100,
            duration: 0.3,
        },
    },
},
particles: {
    color: {
        value: "#000000",
    },
    links: {
        color: "#000000",
        distance: 150,
        enable: true,
        opacity: 0.0,
        width: 1,
    },
    collisions: {
        enable: true,
    },
    move: {
        directions: "none",
        enable: true,
        outModes: {
            default: "bounce",
        },
        random: false,
        speed: 1.5,
        straight: false,
    },
    number: {
        density: {
            enable: true,
            area: 800,
        },
        value: 80,
    },
    opacity: {
        value: 0.5,
    },
    shape: {
        type: "triangle",
    },
    size: {
        value: 3.9,
    },
},
  retina_detect: true
}

export default particlesConfig