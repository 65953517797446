export default function Bottombar() {
  return (
    <div>
        <ul className="belka-dol">
            <li>fotograf</li>
            <li>ilustrator</li>
            <li>projektant</li>
            <li>webdeweloper</li>
        </ul>
    </div>
  )
}