export default function Logo() {
  return (
    <div>

        <div id="logo">
            <img src="img/Logo.png" alt="logo" />
        </div>

    </div>
  )
}