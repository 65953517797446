import { useState, useEffect } from "react"
import { motion } from "framer-motion";

export default function Animation() {
    const [activeFrame, setActiveFrame] = useState(1)

    const variants = {
      initial: {
        scale: 0.8,
        opacity: 0.0,
        transition: {
          duration: .7
        }
      },
      animate: {
        scale: 1,
        opacity: 1,
        transition: {
          duration: .7
        }
      }
    }

    useEffect(() => {
        const interval = setInterval(() => {
          if (activeFrame === 3) {
            setActiveFrame(prevId => prevId - 2);
          } else {
            setActiveFrame(prevId => prevId + 1);
          }
        }, 4000);
        return () => clearInterval(interval);
      }, [activeFrame, setActiveFrame]);


    return (
        <div>
            <div id="glowny">
                <div className={`glownyImgBackground img-background maska0${activeFrame}`}></div>
                <div className={`glownyImgBackground_ maska0${activeFrame}_`}>
                  <motion.img 
                    src={`img/hp00${activeFrame}.png`} 
                    alt="hubert" 
                    key='activeIMG'
                    initial='initial'
                    animate='animate'
                    variants={variants}
                  />
                </div>
                <div className='img-background'>
                </div>
            </div>
        </div>
    )
}