export default function Icons() {
  return (
    <div>
        
        <div id="sociale">
                <div id="ikony">
                    <div><a href="https://www.facebook.com/hrpkreatywnie"><img src="img/001-facebook.svg" alt='icon'/></a></div>
                    <div><a href="https://www.youtube.com/channel/UCgd_ZswnNj2xqHA0XqHpd0A"><img src="img/008-youtube.svg" alt='icon'/></a></div>
                    <div><a href="https://www.linkedin.com/in/hubert-pulik/"><img src="img/010-linkedin.svg" alt='icon'/></a></div>
                    <div><a href="https://www.instagram.com/radzynski.pstrykacz/"><img src="img/011-instagram.svg"alt='icon'/></a></div>
                    <div><a href="https://m.me/hrpkreatywnie"><img src="img/012-messenger.svg" alt='icon'/></a></div>
                    <div><a href="https://github.com/hubertPulik"><img src="img/github-logo.svg" alt='icon'/></a></div>
                    <div><a href="https://www.behance.net/hrp84"><img src="img/be.svg" alt='icon'/></a></div>
                    <div><a href="https://500px.com/p/hubertpulik?view=photos"><img src="img/5.svg" alt='icon'/></a></div>
                </div>
            </div>

    </div>
  )
}