import './App.css';
import Bottombar from './components/Bottombar';
import Topbar from './components/Topbar';
import Start from './pages/Start';
import ParticlesBackground from './components/ParticlesBackground';

function App() {
  return (
    <div className="App">
    <ParticlesBackground />
    <Topbar />
    <Start />
    <Bottombar />
    <div id="particles-js"></div>
    <script src="js/particles.js"></script>
    <script src="js/app.js"></script>
    </div>
  );
}

export default App;