import Icons from "../components/Icons"
import Logo from "../components/Logo"
import Sidebar from "../components/Sidebar"
import Footer from '../components/Footer'
import Animation from "../components/Animation"

export default function Start() {
  return (
    <div className="mainbox">
      <div className="sidebarbox">
        <div>
          <Logo />
          <Sidebar />
        </div>
        <div className="sidebar-bottom">
          <Icons />
          <Footer />
        </div>
      </div>
      <div className="contentbox">
        <div className="animation-holder">
          <Animation />
          <img src='img/background-box.svg' alt="tlo" className="animation-holder-img"/>
        </div>
      </div>
    </div>
  )
}