import { useState } from 'react'
import { data, contactData } from '../data/Data'
 
export default function Sidebar() {
  const [selected, setSelected] = useState(null)
  const [mouseOn, setMouseOn] = useState(null)
  const menuData = data
  const contact = contactData

  const handleClick = (id) => {
    if (id === selected) {
      return setSelected(null)
    }

    setSelected(id)
  }

  const handleHover = (id) => {
    setMouseOn(id)
  }

  

  return (
    <div id="panel-boczny">
        {menuData.map((i) => (
            <div key={i.id}>
                <div className='menu-header'>
                  <div className='arrow-box'>
                    <span className={`${mouseOn === i.id ? 'arrow hover' : 'arrow'} ${selected === i.id ? 'active' : ''}`}>▶</span>
                  </div>
                  <h2 
                    className='menu-title' 
                    onClick={() => handleClick(i.id)}
                    onMouseEnter={() => handleHover(i.id)}
                    onMouseLeave={() => setMouseOn(null)}
                  >
                    {i.title}
                  </h2>
                </div>
                <div className={(selected === i.id) ? 'menu-content show' : 'menu-content'}>
                  <p>{i.content}</p>
                </div>
            </div>
        ))}
        {<div key={contact.id}>
        <div className='menu-header'>
            <div className='arrow-box'>
              <span className={`${mouseOn === contact.id ? 'arrow hover' : 'arrow'} ${selected === contact.id ? 'active' : ''}`}>▶</span>
            </div>
            <h2
              className='menu-title' 
              onClick={() => handleClick(contact.id)}
              onMouseEnter={() => handleHover(contact.id)}
              onMouseLeave={() => setMouseOn(null)}
            >
              {contact.title}
            </h2>
          </div>
          <div className={(selected === contact.id) ? 'menu-content show' : 'menu-content'}>
          <p>{contact.name}</p>
          <p>{contact.phone}</p>
          <p>{contact.mail}</p>
          <p>{contact.url}</p>
          <p>{contact.website}</p>
          </div>
        </div>}
    </div>
  )
}