const data = [
    {
        id: 1,
        title: 'Kim jestem',
        content: 'Mam na imię Hubert. Poruszam się po branży kreatywnej już prawie 10 lat. Zajmuję się głównie fotografią i projektowaniem, ale wykonuję też ilustracje, modele 3D oraz nagrania i montaż wideo. Jako uzupełnienie projektowania stron www zajmuję się też ich kodowaniem i wdrażaniem. Prywatnie szczęśliwy mąż i ojciec, fan siatkówki, koneser dobrego filmu i niespełniony wielbiciel dobrej książki (na razie brak czasu na czytanie). Zawsze chcę wiedzieć jak coś działa i staram się wzbogacać wachlarz umiejętności i zasób wiedzy. Mam na koncie dwie autorskie wystawy z zakresu fotografii krajobrazowej.'
    },
    {
        id: 2,
        title: 'Co tworzę',
        content: 'Największa część mojej pracy twórczej to fotografia, w rozmaitym wydaniu, portretowa, eventowa, reklamowa. W ostatnim czasie równie intensywnie zajmuję się projektowaniem graficznym i UX. Tworzę projekty stron internetowych, aplikacji, czasopism i innych materiałów drukowanych. Dodatkowo zajmuję się budową stron internetowych, w oparciu o HTML/CSS/JS, jak również popularne CMSy. Jako uzupełnienie wolną chwilą tworzę ilustracje, troche modeli 3D. trochę piszę, ćwicząc styl, aby ofertę projektowania uzupełnić o copywrithing.'
    },
    {
        id: 3,
        title: 'Moje projekty',
        content: <a href="https://www.behance.net/hrp84/projects">Moje portfolio na Behance</a>
    }
]

const contactData = {
        id: 4,
        title: 'Kontakt',
        name: 'Hubert Pulik',
        phone: '577 903 117',
        mail: 'radzynski.pstrykacz@gmail.com',
        url: 'https://hrpstudio.pl',
        website: 'www.HRPstudio.pl'
    }

export { data, contactData }