const icons = ['Ps', 'Lr', 'Ai', 'In', 'xD', 'html', 'CSS', 'JS', 'React', 'Pr', 'AF']

export default function Topbar() {
  return (
    <div>
         <ul className="gorna-belka">
                {icons.map((item) => (
                    <li key={item}>{item}</li>
                ))}
            </ul>
    </div>
  )
}